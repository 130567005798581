// @flow

import style from "./style.module.scss";

import React, {
    useState,
    useCallback,
    useMemo,
    Fragment,
    useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "gatsby";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import moment from "moment";

import { LoadingButton, PersonsModal } from "@components/Shared";
import { ASSIGNMENTS, NOTIFICATIONS } from "@constants";
import { getReviewer, PersonUtil, displayDate } from "@utils";
import type { PersonModalType, Person } from "@types";
import { usePermission, useMember } from "@hooks";

// redux actions
import { createDraftReport } from "@stores/create-draft-report";
import { createFinalReport } from "@stores/create-final-report";
import { loadInspection } from "@stores/inspection";
import { updateReviewer } from "@stores/update-reviewer";
import { addReviewer } from "@stores/add-reviewer";
import { update as notifyAction } from "@stores/notifications";
import { getReportsForAssignment } from "@stores/get-reports-for-assignment";

// selectors
const assignmentSelector = (state: *) => state.inspection.data;
const draftReportStoreSelector = (state: *) => state.createDraftReport;
const updateReviewerStoreSelector = (state: *) => state.updateReviewer;
const addReviewerStoreSelector = (state: *) => state.addReviewer;

/**
 *  Hoisted code
 */
const DraftReportButtonTooltip = () => (
    <Fragment>
        Om een ontwerpverslag te maken, moet
        <ul>
            <li>Elke module ingevuld en ingediend zijn</li>
            <li>De datum laatste vaststelling ingevuld zijn</li>
            <li>De verslagtitel ingevuld zijn</li>
            <li>
                Aangegeven zijn dat er geen bezoek is of een geldig bezoekmoment
                ingevuld zijn
            </li>
        </ul>
    </Fragment>
);
const readyForDraftPhase = (status: string) =>
    [
        ASSIGNMENTS.STATUSSES.DRAFT_READY_TO_PUBLISH,
        ASSIGNMENTS.STATUSSES.DRAFT_REVIEWED,
        ASSIGNMENTS.STATUSSES.PREPARED,
    ].includes(status);

const finalPhaseStatuses = [
    ASSIGNMENTS.STATUSSES.REACTION_PROCESSED,
    ASSIGNMENTS.STATUSSES.FINAL_REVIEWED,
];

const finalReportStatuses = [
    ASSIGNMENTS.STATUSSES.REACTION_PROCESSED,
    ASSIGNMENTS.STATUSSES.DRAFT_REPORT_PUBLISHED,
    ASSIGNMENTS.STATUSSES.REOPENED,
    ASSIGNMENTS.STATUSSES.FINAL_READY_TO_PUBLISH,
];

const warningModal = {
    type: NOTIFICATIONS.TYPE.MODAL,
    severity: NOTIFICATIONS.SEVERITY.WARNING,
};
const successModal = {
    type: NOTIFICATIONS.TYPE.MODAL,
    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
};

/**
 * CreateReportStartQC
 */
type Props = {
    id: string,
    orientation: "vertical" | "horizontal",
};
const CreateReportStartQC = ({ id, orientation }: Props) => {
    const [showModal, toggleModal] = useState(false);
    const [disableReportButtons, setDisableReportButtons] = useState(false);
    const [isFinalReportLoading, setIsFinalReportLoading] = useState(false);

    const dispatch = useDispatch();
    const assignment = useSelector(assignmentSelector);
    const draftReportStore = useSelector(draftReportStoreSelector);
    const updateReviewerStore = useSelector(updateReviewerStoreSelector);
    const addReviewerStore = useSelector(addReviewerStoreSelector);

    const userIsMemberOfAssignment = useMember();
    const hasPermissionToStartQC = usePermission(
        "assignment.startQualityControl.write",
    );
    const hasPermissionToCreateDraftReport = usePermission(
        "reports.createDraftReport.write",
        assignment?.status,
    );
    const hasPermissionToCreateFinalReport = usePermission(
        "reports.createFinalReport.write",
        assignment?.status,
    );

    const readyForFinalPhase =
        !!assignment &&
        ((!assignment.allowReactions &&
            readyForDraftPhase(assignment.status)) ||
            finalPhaseStatuses.includes(assignment.status));

    const isReopened = assignment?.status === ASSIGNMENTS.STATUSSES.REOPENED;

    const canStartQC = hasPermissionToStartQC || userIsMemberOfAssignment;

    const canCreateDraftReport =
        !!assignment &&
        (hasPermissionToCreateDraftReport || userIsMemberOfAssignment) &&
        readyForDraftPhase(assignment?.status) &&
        assignment?.allowReactions;
    useEffect(() => {
        setDisableReportButtons(
            !assignment?.lastObservationDoneOn ||
                !assignment?.reportGenerationAvailable ||
                !assignment?.atLeastOneModuleSubmitted,
        );
    }, [assignment]);
    const meetsFinalReportExtraConditions = (assignment?: *) => {
        if (!assignment) return false;
        //if (assignment.draftReportSentOn === null) return false;
        if (assignment.allowReactions)
            return finalReportStatuses.includes(assignment.status);

        // only applicable if !allowReactions
        if (disableReportButtons) return false;
        return (
            readyForDraftPhase(assignment.status) ||
            [
                ASSIGNMENTS.STATUSSES.FINAL_REVIEWED,
                ASSIGNMENTS.STATUSSES.REOPENED,
            ].includes(assignment.status)
        );
    };

    const canCreateFinalReport =
        !!assignment &&
        (hasPermissionToCreateFinalReport || userIsMemberOfAssignment) &&
        (readyForFinalPhase || isReopened || hasPermissionToCreateFinalReport);
    const notify = useCallback(
        (options: *) => dispatch(notifyAction(options)),
        [dispatch],
    );

    const refreshData = useCallback(
        (callback?: Function) => {
            assignment?.inspectionId &&
                dispatch(loadInspection(assignment.inspectionId)).then(
                    action => {
                        if (action) {
                            dispatch(
                                getReportsForAssignment(
                                    assignment.inspectionId,
                                ),
                            );
                            callback && callback();
                        }
                    },
                );
        },
        [dispatch, assignment?.inspectionId],
    );

    const selectedPersons = useMemo(() => getReviewer(assignment), [
        assignment,
    ]);

    const handleSelectPerson = (type: PersonModalType, person: Person) => {
        if (!assignment || !assignment.inspectionId) return;
        const { status, inspectionId } = assignment;

        const doneHandler = response => {
            if (!response) return;
            toggleModal(false);
            notify({
                ...successModal,
                message: `${PersonUtil.display(
                    person,
                )} toegewezen als kwaliteitscontroleur`,
                primaryAction: () => navigate("/opdrachten/mijn-opdrachten/"),
                primaryActionText: "Ok",
            });
        };
        // review in progress, reassign to other
        if (status === ASSIGNMENTS.STATUSSES.DRAFT_REVIEW_REQUESTED)
            dispatch(updateReviewer(inspectionId, person)).then(doneHandler);
        // no review in progress, regular assign
        else dispatch(addReviewer(inspectionId, person)).then(doneHandler);
    };

    const assignLeadInspectorAsQualityController = () => {
        if (!assignment || !assignment.inspectionId) return;

        const { leadInspector, inspectionId } = assignment;
        // does put with empty uuid, followed by post with person's id
        inspectionId &&
            dispatch(updateReviewer(inspectionId, leadInspector)).then(
                response => {
                    if (!response) return;
                    refreshData();
                    toggleModal(false);
                    notify({
                        message:
                            "Kwaliteitscontroleur werd succesvol verwijderd",
                        severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    });
                },
            );
    };

    const confirmDeleteQualityController = () => {
        notify({
            ...warningModal,
            message: `Bent u zeker dat u deze kwaliteitscontroleur wilt verwijderen?`,
            primaryAction: assignLeadInspectorAsQualityController,
            primaryActionText: "Ok",
            secondaryActionText: "Annuleer",
        });
    };

    const openModal = () => toggleModal(true);

    const toReportButtonCallback = useCallback(() => {
        dispatch(getReportsForAssignment(assignment.inspectionId));
        return navigate(`/opdrachten/${assignment.inspectionId}/verslagen`);
    }, [dispatch, assignment?.inspectionId]);

    const confirmationNotify = (message: string) =>
        notify({
            ...successModal,
            message,
            primaryActionText: "OK",
            primaryAction: refreshData,
            secondaryActionText: "Naar verslagen",
            secondaryAction: () => refreshData(toReportButtonCallback),
        });

    const handleFinalReportConfirm = () => {
        if (!assignment?.inspectionId) return;
        setIsFinalReportLoading(true);
        dispatch(createFinalReport(assignment.inspectionId)).then(response => {
            response &&
                confirmationNotify("Definitief verslag werd aangemaakt");
            response && setIsFinalReportLoading(false);
        });
    };

    const handleDraftReportConfirm = () => {
        if (!assignment?.inspectionId) return;

        dispatch(createDraftReport(assignment.inspectionId)).then(
            response =>
                response && confirmationNotify("Ontwerpverslag aangemaakt"),
        );
    };

    const handleSendReport = (final: boolean) => () => {
        if (!assignment || !assignment.inspectionId) return;

        const visitDates = [];
        assignment.visits.forEach(visit => {
            if (visit.visitPeriods.length > 0) {
                visit.visitPeriods.forEach(period => {
                    visitDates.push(moment(period.startVisitDate));
                });
            }
        });

        const latestVisitDate =
            visitDates.length > 0 ? moment.max(visitDates) : null;

        const primaryAction = final
            ? handleFinalReportConfirm
            : handleDraftReportConfirm;

        const confirmMessage = final ? (
            "Definitief verslag wordt aangemaakt"
        ) : (
            <span>
                Na het omzetten van het ontwerp verslag naar PDF is
                kwaliteitscontrole niet meer mogelijk. Ben je zeker dat je de
                PDF wil maken?
                <br key="001" />
                <br key="002" />
                <strong key="003">
                    Datum laatste vaststelling:{" "}
                    {displayDate(assignment.lastObservationDoneOn)}
                </strong>
                <br key="004" />
                <strong key="005">
                    Datum laatste bezoek: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {latestVisitDate
                        ? moment(latestVisitDate).format("DD/MM/YYYY")
                        : "Geen bezoeken gepland."}
                </strong>
            </span>
        );

        notify({
            ...warningModal,
            message: confirmMessage,
            primaryAction: primaryAction,
            primaryActionText: final ? "Ok" : "Ja",
            secondaryActionText: final ? "Annuleren" : "Nee",
        });
    };

    return (
        <Fragment>
            <PersonsModal
                id={`${id}-personModal`}
                isOpen={showModal}
                title="Reviewer toewijzen"
                onClose={() => toggleModal(false)}
                onSelect={handleSelectPerson}
                onRemove={confirmDeleteQualityController}
                personModalType="reviewer"
                inspectionPointId={
                    assignment?.inspectionPoint?.inspectionPointId
                }
                selectedPersons={selectedPersons}
                callInProgress={
                    addReviewerStore.loading || updateReviewerStore.loading
                }
                showPills={false}
                single={true}
                onlyInspectors={false}
                excludeCurrentUser
                hidePersonsForIp={true}
                withConfirmation={true}
            />

            <div id={`${id}-wrapper`} className={style[orientation]}>
                {canStartQC &&
                    readyForDraftPhase(assignment?.status) &&
                    assignment?.atLeastOneModuleSubmitted && (
                        <Button
                            id={`${id}-btnStartDraftQualityControl`}
                            variant="contained"
                            color="primary"
                            onClick={openModal}
                            fullWidth={orientation === "vertical"}
                        >
                            Kwaliteitscontrole
                        </Button>
                    )}
                {canCreateDraftReport && (
                    <div className={style.draftReportWrapper}>
                        <LoadingButton
                            id={`${id}-btnCreateDraftReport`}
                            variant="contained"
                            color="primary"
                            loading={draftReportStore.loading}
                            disabled={disableReportButtons}
                            onClick={handleSendReport(false)}
                            fullWidth={orientation === "vertical"}
                        >
                            Ontwerpverslag aanmaken
                        </LoadingButton>
                        {readyForDraftPhase(assignment?.status) &&
                            disableReportButtons && (
                                <Tooltip
                                    title={<DraftReportButtonTooltip />}
                                    placement="top"
                                    arrow
                                >
                                    <HelpIcon />
                                </Tooltip>
                            )}
                    </div>
                )}

                {canStartQC &&
                    (assignment?.status ===
                        ASSIGNMENTS.STATUSSES.REACTION_RECEIVED ||
                        assignment?.status ===
                            ASSIGNMENTS.STATUSSES.FINAL_REVIEWED) && (
                        <Button
                            id={`${id}-btnStartFinalQualityControl`}
                            variant="contained"
                            color="primary"
                            onClick={openModal}
                            fullWidth={orientation === "vertical"}
                        >
                            Kwaliteitscontrole Definitief verslag
                        </Button>
                    )}
                {canCreateFinalReport && (
                    <LoadingButton
                        id={`${id}-btnCreateFinalReport`}
                        variant="contained"
                        color="primary"
                        disabled={
                            !meetsFinalReportExtraConditions(assignment) ||
                            !canCreateFinalReport ||
                            isFinalReportLoading
                        }
                        loading={isFinalReportLoading}
                        onClick={handleSendReport(true)}
                        fullWidth={orientation === "vertical"}
                    >
                        Definitief verslag aanmaken
                    </LoadingButton>
                )}
            </div>
        </Fragment>
    );
};

export default CreateReportStartQC;
